<template>
  <v-container
    class="my-6 mx-auto"
    style="max-width: 1500px; background: white !important"
  >
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Зарлагдсан сорилууд (ЭЕШ800)
        </h5>
        <p class="text-body">ЭЕШ800 -гаас зардлагдсан сорилууд</p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            ms-auto
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          @click="_createAssignmentDatabase(null)"
          >Сорил үүсгэx</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="start" v-if="assignments && assignments.length > 0">
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in assignments"
        :key="assignment.id"
      >
        <AssignmentCard
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
        ></AssignmentCard>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">Одоогоор сорил байxгүй байна.</h5>
    </v-row>
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ сорил/шалгалт үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-radio-group
                v-model="editedItem.assignmentType"
                :rules="[rules.required]"
              >
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCard from "./AssignmentCard.vue";

export default {
  components: { AssignmentCard },
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories: null,
  }),
  props: {
    allAssignments: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
    },
    // myAssignments() {
    //   if (this.assignments && this.userData)
    //     return this.assignments.filter(
    //       (el) => el.createdByEmail == this.userData.email
    //     );
    //   else return null;
    // },
    // otherAssignments() {
    //   if (this.assignments)
    //     return this.assignments.filter(
    //       (el) => el.createdByEmail != (this.userData && this.userData.email)
    //     );
    //   else return null;
    // },
  },
  created() {
    this._setupp();
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.assignmentType &&
        this.editedItem.selectedLessonCategory
      ) {
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }

          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          fb.dbeyesh800
            .collection("assignmentsdatabase")
            .doc()
            .set(this.editedItem)
            .then(() => {
              console.log("Document xxxxx written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _findx(ccc) {
      console.log(ccc, this.lessonCategories.length);
      var number = 0;
      if (this.lessonCategories) {
        for (var lcategory of this.lessonCategories) {
          if (lcategory.ref.path == ccc) {
            number = lcategory.counter;
            break;
          }
        }
      }
      return number;
    },
    xxx() {
      var query = fb.dbeyesh800
        .collection(this.assignmentsDatabasePath)
        .where("createdByEmail", "==", "granditxxk@gmail.com")
        .where("deleted", "==", false);

      // query = query.where("public", "==", true);

      // if (
      //   !(
      //     this.userData &&
      //     (this.userData.role == "superadmin" ||
      //       this.userData.role == "aspecialist" ||
      //       this.userData.role == "eyeshadmin")
      //   )
      // ) {
      //   query = query.where("createdByEmail", "==", this.userData.email).where("public","==", true);
      // }

      query.orderBy("createdAt", "desc").onSnapshot((docs) => {
        this.assignments = [];
        docs.forEach((doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;
          //this.assignments.push(assign);
          // assign.yyy = this._findx(assign.categoryRef.path);
          // console.log(assign.yyy)
          console.log(this.allAssignments)
          if (this.allAssignments) {
            this.assignments.push(assign);
          } else {
            if (
              this.$attrs.categoryRefPath &&
              assign.categoryRef.path == this.$attrs.categoryRefPath
            ) {
              if (this.userData.school) {
                if (
                  assign.createdBySchoolRef.path ==
                  this.userData.school.ref.path
                )
                  this.assignments.push(assign);
              } else this.assignments.push(assign);
            }
          }
        });
      });
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.dbeyesh800
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories = [];
            docs.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              this.lessonCategories.push(item);
            });
            console.log(this.lessonCategories.length);
            this.xxx();
          });
      } else {
        if (this.userData && this.userData.school) {
          this.userData.school.ref
            .collection("categories")
            .orderBy("name", "asc")
            .onSnapshot((docs) => {
              this.lessonCategories = [];
              docs.forEach((doc) => {
                let item = doc.data();
                item.id = doc.id;
                item.ref = doc.ref;
                item.name2 = item.name + " - " + item.lessonTypeName;
                this.lessonCategories.push(item);
              });
              this.xxx();
            });
        }
      }
    },
  },
};
</script>
 